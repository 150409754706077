<template>
    <div class="view-palavra">
        <div v-if="vereadorGrantedWord" class="caixa-com-palavra row">
            <div class="col-12" :style="{ 'font-size': fontSizeNomeVereador }">
                <transition
                    name="custom-classes-transition"
                    enter-active-class="animated bounceInLeft"
                    leave-active-class="animated bounceOutRight"
                >
                    <NomeParlamentarComPalavra 
                        :vereador="vereadorGrantedWord" 
                        :exibir-apenas-apelido-vereador="exibirApenasApelidoVereador"
                    />
                </transition>
            </div>

            <div class="col-12 caixa-foto-cronometro">
                <div :style="{ 'font-size': sizeFotoVereador }">
                    <transition
                        name="custom-classes-transition"
                        enter-active-class="animated bounceInLeft"
                        leave-active-class="animated bounceOutRight"
                    >
                        <FotoParlamentarComPalavra
                            :partido="vereadorGrantedWord.partido.sigla"
                            :url-foto="vereadorGrantedWord.links.image"
                        />
                    </transition>
                </div>
                <div :style="{ 'font-size': fontSizeCronometro }">
                    <transition
                        name="custom-classes-transition"
                        enter-active-class="animated bounceInRight"
                        leave-active-class="animated bounceOutLeft"
                    >
                        <CronometroComPalavra :horas="0" :minutos="minutes" :segundos="seconds" :is-negativo="isTimerUp" />
                    </transition>
                </div>
            </div>
        </div>
        <div v-else class="caixa-nenhum-discurso">
            Ninguém está discursando
        </div>
    </div>
</template>

<script>
import SessaoServiceTelao from '../domain/SessaoServiceTelao';
const NomeParlamentarComPalavra = () => import('../components/telao/comPalavra/NomeParlamentarComPalavra.vue');
const FotoParlamentarComPalavra = () => import('../components/telao/comPalavra/FotoParlamentarComPalavra.vue');
const CronometroComPalavra = () => import('../components/telao/comPalavra/CronometroComPalavra.vue');

export default {
    components: {
        NomeParlamentarComPalavra,
        FotoParlamentarComPalavra,
        CronometroComPalavra,
    },

    props: {
        telaoSettings: {
            type: Object,
            required: false
        },
        exibirApenasApelidoVereador: {
            type: Boolean,
            required: false
        }
    },

    data() {
        return {
            sessaoServiceTelao: new SessaoServiceTelao(this.$route.params.dominio),
            vereadorGrantedWord: null,
            tempo: null,
            isOpenedVotacao: false,
            nowTime: null,
            tempoAcabando: false,
            animacaoTimer: false,
            distance: null,
            intervalDown: null,
            intervalUp: null,
            minutes: '00',
            seconds: '00',
            isTimerUp: false
        };
    },

    watch: {
        seconds(value) {
            if (this.minutes <= 0 && value <= 0) {
                this.tempoAcabando = true;
                this.animacaoTimer = false;
            } else if (this.minutes <= 0 && value <= 10 && this.isTimerUp !== true) {
                this.tempoAcabando = true;
                this.animacaoTimer = true;
            }
        }
    },

    created: async function() {
        const dominio = this.$route.params.dominio;

        const ACOMPANHAMENTO_CHANNEL = window.Echo.channel(`portyx_${dominio}_sessao${this.$sessaoId}_acompanhamento`);
        const NAMESPACE_PREFIX = '.App\\Events\\AcompanhamentoSessao\\';
        const ENDPOINT_BASE = `portyx_${dominio}-sessao-${this.$sessaoId}`;

        /** Concede a palavra ao parlamentar, com o tempo pausado */
        ACOMPANHAMENTO_CHANNEL.listen(NAMESPACE_PREFIX + 'PalavraConcedida', () => {
            this.sessaoServiceTelao
                .getLastGrantedWord(this.$sessaoId)
                .then(response => this.showGrantedWord(response))
                .catch(err => err);
        });

        /** Inicia o tempo da palavra concedida */
        ACOMPANHAMENTO_CHANNEL.listen(NAMESPACE_PREFIX + 'PalavraIniciada', () => {
            this.sessaoServiceTelao
                .getLastGrantedWord(this.$sessaoId)
                .then(response => this.showGrantedWord(response))
                .catch(err => err);
        });

        /** Interrompe a palavra concedida */
        ACOMPANHAMENTO_CHANNEL.listen(NAMESPACE_PREFIX + 'PalavraInterrompida', () => {
            this.stopWord();
        });

        window.Echo.channel(`${ENDPOINT_BASE}-word`).listen('GrantWordEvent', ({ vereador }) => {
            this.vereadorGrantedWord = JSON.parse(vereador);

            clearInterval(this.intervalDown);
            clearInterval(this.intervalUp);
            this.minutes = 0;
            this.seconds = 0;

            this.sessaoServiceTelao
                .getLastGrantedWord(this.$sessaoId)
                .then(response => this.showGrantedWord(response))
                .catch(err => err);
        });

        /*window.Echo.channel(`${ENDPOINT_BASE}-stop-word`).listen('SessaoVereadorStopWord', () => {
            this.stopWord();
        });*/
    
        this.sessaoServiceTelao
            .getLastGrantedWord(this.$sessaoId)
            .then(response => this.showGrantedWord(response))
            .catch(err => err);
    },

    mounted: function() {
        this.timeFormate();
        setInterval(this.timeFormate, 30 * 1000);
    },

    computed: {
        secondsFormated() {
            return Math.floor((this.distance / 1000) % 60);
        },
        minutesFormated() {
            return Math.floor((this.distance / 1000 / 60) % 60);
        },
        fontSizeNomeVereador() {
            let baseFontSize = 30;
            if (!this.telaoSettings || !this.telaoSettings.hasOwnProperty('comPalavra')) {
                return baseFontSize + 'px';
            }
            let fontSizeFactor = this.telaoSettings.comPalavra.font_size_nome_vereador || 1;
            return Math.round(baseFontSize * fontSizeFactor) + 'px';
        },
        fontSizeCronometro() {
            let baseFontSize = 50;
            if (!this.telaoSettings || !this.telaoSettings.hasOwnProperty('comPalavra')) {
                return baseFontSize + 'px';
            }
            let fontSizeFactor = this.telaoSettings.comPalavra.font_size_cronometro || 1;
            return Math.round(baseFontSize * fontSizeFactor) + 'px';
        },
        sizeFotoVereador() {
            let baseFontSize = 18;
            if (!this.telaoSettings || !this.telaoSettings.hasOwnProperty('comPalavra')) {
                return baseFontSize + 'px';
            }
            let fontSizeFactor = this.telaoSettings.comPalavra.size_foto_vereador || 1;
            return Math.round(baseFontSize * fontSizeFactor) + 'px';
        },
},

    methods: {
        showGrantedWord(response) {
            if (response.data.word !== null) {
                if (response.data.word.stoped_at === null) {
                    this.vereadorGrantedWord = response.data.vereador;
                    this.tempo = response.data.word.tempo;
                    console.log(response.data.word);
                    this.wordTime(response.data.word.tempo, response.data.word.started_at);
                }
            }

            this.grantedWordLoading = false;
        },

        stopWord() {
            this.vereadorGrantedWord = null;
            clearInterval(this.intervalDown);
            clearInterval(this.intervalUp);
        },

        timeFormate() {
            let cd = new Date();
            this.nowTime = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2);
        },

        zeroPadding(num, digit) {
            let zero = '';
            for (let i = 0; i < digit; i++) {
                zero += '0';
            }
            return (zero + num).slice(-digit);
        },

        wordTime(time, initialTime) {
            this.tempoAcabando = false;
            this.animacaoTimer = false;

            let countDownDate;

            countDownDate = initialTime ? new Date(initialTime) : new Date();
            countDownDate.setMinutes(countDownDate.getMinutes() + time);

            let now = new Date();

            this.distance = countDownDate - now;

            if (this.distance <= 0) {
                this.tempoAcabando = true;

                this.timerUp(false);
            } else {
                this.timerDown(initialTime);
            }
        },

        timerDown(initialTime) {
            let minutes = this.minutesFormated;
            let seconds = this.secondsFormated;
            this.isTimerUp = false;

            if(initialTime == null){
                this.minutes = Math.abs(minutes);
                this.seconds = Math.abs(seconds);
            }else{
                this.intervalDown = setInterval(() => {
                    if (seconds <= 0) {
                        if (minutes <= 0) {
                            this.animacaoTimer = false;
                            clearInterval(this.intervalDown);
                            this.timerUp(true);
                        } else {
                            minutes--;
                            seconds = 59;
                        }
                    }

                    this.minutes = Math.abs(minutes);
                    this.seconds = Math.abs(seconds);

                    seconds--;
                }, 1020);
            }
        },

        timerUp(initialTime) {
            let seconds;
            let minutes;
            this.isTimerUp = true;

            if (initialTime === true) {
                minutes = 0;
                seconds = 0;
            } else {
                minutes = Math.abs(this.minutesFormated) - 1;
                seconds = Math.abs(this.secondsFormated);
            }

            this.intervalUp = setInterval(() => {
                seconds++;
                if (seconds >= 60) {
                    minutes++;
                    seconds = 0;
                }

                this.minutes = Math.abs(minutes);
                this.seconds = Math.abs(seconds);
            }, 1000);
        }
    },

    beforeDestroy() {
        clearInterval(this.intervalDown);
        clearInterval(this.intervalUp);
        this.distance = null;
    }
};
</script>

<style scoped lang="scss">
.view-palavra {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}
.caixa-nenhum-discurso {
    font-size: 30px;
    text-align: center;
    color: white;
    letter-spacing: 1px;
    @media (min-width: 640px) { font-size: 35px; }
    @media (min-width: 768px) { font-size: 38px; }
    @media (min-width: 1024px) { font-size: 42px; }
    @media (min-width: 1280px) { font-size: 46px; }
    @media (min-width: 1536px) { font-size: 50px; }
    @media (min-width: 2000px) { font-size: 55px; }
    @media (min-width: 2400px) { font-size: 57px; }
    @media (min-width: 2800px) { font-size: 60px; }
    @media (min-width: 3200px) { font-size: 65px; }
    @media (min-width: 3600px) { font-size: 70px; }
    @media (min-width: 4000px) { font-size: 76px; }
}
.caixa-foto-cronometro {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
}
</style>
